<!-- Mto particular de Compañías Centrales -->

<template>
  <div class="ciac_M"  v-if="schema">

    <!-- Cabecera -->
    <div class="cabecera">
      <base_Header
        v-if="Entorno.header.header"       
        :Entorno="Entorno.header"
        @onEvent="event_Header">
      </base_Header>
    </div>

    <v-sheet :elevation="4">
     
      <!-- Slot con los controles del Mto -->
      <div class="contenedor" style="width:1200px">
        <!-- Botones Mto -->        
        <btramto
            :btra_permisos="schema.btra"
            :btra_particular="Entorno.btra"
            btra_modulo="m"
            :estado="estado"
            @onEvent="event_btra_Mto">        
        </btramto>

        <div class="conflex">
          <div class="columna" style="width:64%">
            <div class="conflex">
              <ctrlcontact 
                style="flex: 1 1 50%"
                :schema="schema" 
                :edicion="is_edit"> 
              </ctrlcontact>

              <div class="columna" style="flex: 1 1 50%">          
                <div class="cab">FUNERARIA</div>
                <v-sheet v-bind="$cfg.styles.marco">       
                  <v-text-field
                    style="flex: 1 1 70%"
                    v-bind="$input"
                    v-model="schema.ctrls.name_afecta_c.value"
                    :label="schema.ctrls.name_afecta_c.label"
                    readonly>
                  </v-text-field>
          
                  <v-text-field
                    style="flex: 1 1 30%"
                    v-bind="$input"
                    v-model="schema.ctrls.afecta_name.value"
                    :label="schema.ctrls.afecta_name.label"
                    readonly>
                  </v-text-field>

                  <div class="conflex"> 
                    <v-select
                      style="flex: 1 1 70%"
                      :items="$store.state.datos_iniciales.afecta_tipo"
                      :disabled="!is_edit"
                      item-value="id"
                      item-text="name"
                      v-bind="$select"
                      v-model="schema.ctrls.afecta_apd.value">
                    </v-select>
                    
                    <v-checkbox
                      style="flex: 1 1 30%"
                      v-bind="$checkbox"
                      v-model="schema.ctrls.liquidagencia.value"
                      :label="schema.ctrls.liquidagencia.label"
                      :disabled="!is_edit">
                    </v-checkbox>
                  </div>

                  <ctrlfinder
                    finderName="funes_F" 
                    :schema="schema.ctrls.afecta_f">
                  </ctrlfinder>
                </v-sheet>
              </div>
            </div>

            <div class="columna" style="flex: 1 1 50%">          
              <div class="cab">DATOS COMPAÑÍA</div>
              <v-sheet v-bind="$cfg.styles.marco">          
                <div class="conflex"> 
                  <v-text-field
                    style="flex: 1 1 25%"
                    v-bind="$input"
                    v-model="schema.ctrls.tlf1.value"
                    :label="schema.ctrls.tlf1.label"
                    :disabled="!is_edit">
                  </v-text-field>
                
                  <v-text-field
                    style="flex: 1 1 25%"
                    v-bind="$input"
                    v-model="schema.ctrls.tlf2.value"
                    :label="schema.ctrls.tlf2.label"
                    :disabled="!is_edit">
                  </v-text-field>
               
                  <v-text-field
                    style="flex: 1 1 25%"
                    v-bind="$input"
                    v-model="schema.ctrls.tlf3.value"
                    :label="schema.ctrls.tlf3.label"
                    :disabled="!is_edit">
                  </v-text-field>
                
                  <v-text-field
                    style="flex: 1 1 25%"
                    v-bind="$input"
                    v-model="schema.ctrls.fax1.value"
                    :label="schema.ctrls.fax1.label"
                    :disabled="!is_edit">
                  </v-text-field>
                </div>

                <div class="conflex"> 
                  <!-- <v-checkbox
                    v-bind="$checkbox"
                    v-model="schema.ctrls.representante.value"
                    label=""
                    :disabled="!is_edit"
                  >
                  </v-checkbox> -->
                
                  <v-text-field
                    style="flex: 1 1 70%"
                    v-bind="$input"
                    v-model="schema.ctrls.cont1.value"
                    :label="schema.ctrls.cont1.label"
                    :disabled="!is_edit">
                  </v-text-field>
              
                  <v-text-field
                    style="flex: 1 1 30%"
                    v-bind="$input"
                    v-model="schema.ctrls.diredni.value"
                    :label="schema.ctrls.diredni.label"
                    :disabled="!is_edit">
                  </v-text-field>
                </div>
                
                <div class="conflex"> 
                  <v-text-field
                    v-bind="$input"
                    v-model="schema.ctrls.email1.value"
                    :label="schema.ctrls.email1.label"
                    :disabled="!is_edit">
                  </v-text-field>

                  <v-text-field
                    v-bind="$input"
                    v-model="schema.ctrls.email2.value"
                    :label="schema.ctrls.email2.label"
                    :disabled="!is_edit">
                  </v-text-field>
                </div>
              </v-sheet>
            </div>

            <ctrlobs3
              :schemaComponente="schema.ctrls.obs"
              :schema="schema"   
              :record="accionRow"
              :edicion="is_edit">
            </ctrlobs3>
          </div>

          <div class="columna" style="margin-left:10px;width:35%">
            <div class="cab">CLASIFICACIÓN</div>
            <v-sheet v-bind="$cfg.styles.marco">
              <div class="conflex">
                <v-select
                  style="flex: 1 1 50%"
                  :items="$store.state.datos_iniciales.ciasitu"
                  :disabled="!is_edit"
                  item-value="id"
                  item-text="label"
                  v-bind="$select"
                  v-model="schema.ctrls.situ.value">
                </v-select>

                <v-select
                  style="flex: 1 1 50%"
                  :items="$store.state.datos_iniciales.ciastipo"
                  :disabled="!is_edit"
                  item-value="id"
                  item-text="name"
                  v-bind="$select"
                  v-model="schema.ctrls.tipo.value">
                </v-select>
              </div>

              <v-text-field
                v-bind="$input"
                v-model="schema.ctrls.ccont.value"
                :label="schema.ctrls.ccont.label"
                :disabled="!is_edit">
              </v-text-field>
            </v-sheet>
          
            <div class="cab">GRUPO</div>
            <v-sheet v-bind="$cfg.styles.marco">
              <v-select
                :items="$store.state.datos_iniciales.grupos"
                :disabled="!is_edit"
                item-value="id"
                item-text="name"
                v-bind="$select"
                v-model="schema.ctrls.grupo.value">
              </v-select>
            </v-sheet>

            <div class="columna" style="flex: 1 1 50%">          
              <div class="cab">FACTURACIÓN</div>
              <v-sheet v-bind="$cfg.styles.marco">          
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.admoncont.value"
                  :label="schema.ctrls.admoncont.label"
                  :disabled="!is_edit">
                </v-text-field>

                <div class="conflex"> 
                  <v-text-field
                    v-bind="$input"
                    v-model="schema.ctrls.admontlf1.value"
                    :label="schema.ctrls.admontlf1.label"
                    :disabled="!is_edit">
                  </v-text-field>
                
                  <v-text-field
                    v-bind="$input"
                    v-model="schema.ctrls.admonfax1.value"
                    :label="schema.ctrls.admonfax1.label"
                    :disabled="!is_edit">
                  </v-text-field>
                </div>

                <div class="conflex">
                  <v-text-field
                    v-bind="$input"
                    v-model="schema.ctrls.banco.value"
                    :label="schema.ctrls.banco.label"
                    :disabled="!is_edit">
                  </v-text-field>

                  <v-text-field
                    v-bind="$input"
                    v-model="schema.ctrls.cuenta.value"
                    :label="schema.ctrls.cuenta.label"
                    :disabled="!is_edit">
                  </v-text-field>
                </div>

                <div class="conflex">
                  <v-text-field
                    v-bind="$input"
                    v-model="schema.ctrls.admonemail.value"
                    :label="schema.ctrls.admonemail.label"
                    :disabled="!is_edit">
                  </v-text-field>
                </div>

                <ctrlurl 
                  :schema="schema.ctrls.url" 
                  :edicion="is_edit">
                </ctrlurl>
              </v-sheet>
            </div>
          </div>
        </div>

      </div>
    </v-sheet>
  </div>
</template>



<script>
  import { mixinMto } from "@/mixins/mixinMto.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
  const btramto = () => plugs.groute("btramto_new.vue", "comp");
  const ctrlcontact = () => plugs.groute("ctrlcontact.vue", "comp");
  const ctrlfinder = () => plugs.groute("ctrlfinder.vue", "comp");
  const ctrlurl = () => plugs.groute("ctrlurl.vue", "comp");
  const ctrlobs3 = () => plugs.groute("ctrlobs3.vue", "comp");

  export default {
    mixins: [mixinMto],
    components: { base_Header, btramto, ctrlcontact, ctrlfinder, ctrlurl, ctrlobs3 },
    props: {
      padre: { type:String, default: '' }, 
      accion: { type:[Number, String], default: ''},
      accionRow: { type:Object, default: null},
      disparo: { type:Boolean, default:false },      
      componenteTipo: { type:String, default:'M' },
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.M)); }}
    },

    data() {
      return {
        schema: null,
        api:'ciac_M',
        stName:'stMciac',
        disparoFinder:false, 

        itemsGrupo: []
      };
    },


    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // guardo del store en variables locales
        this.schema = this.$store.state[this.stName].schema;  

        // entorno
        this.Entorno.header.header= true;
        this.Entorno.header.titulo = "Compañías Centrales";
        this.Entorno.btra = JSON.parse(JSON.stringify(this.$cfg.btra_tipo.standardM));

        // items adicionales
        //aray de grupos
        this.itemsGrupo= this.$store.state.datos_iniciales.grupos;
        this.itemsGrupo.unshift({ id:"0", name:'(Selecciona Grupo)'})
      },

    }
  };
</script>
